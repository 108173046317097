/*
 * @Author: fangheng fangheng@wedobest.com.cn
 * @Date: 2023-07-03 17:15:48
 * @LastEditors: fangheng fangheng@wedobest.com.cn
 * @LastEditTime: 2023-07-07 20:09:05
 * @FilePath: \purchase-web\src\api\payManage\payManage.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * Create by zhuzhaoqing
 * Description: permission
 * Date: 2023/3/31 16:05
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from '../request'

// 支付类型查询接口
export const getPayChannel = () => request('payAdminServer/merchants/payChannel', null, 'GET')
// 主体查询接口
export const getEntity = () => request('payAdminServer/merchants/entity', null, 'GET')
// 主体查询接口
export const getEntityBuFen = () => request('payAdminServer/merchants/entity/query', null, 'GET')
// 列表查询
export const getQueryList = (data: any) => request('payAdminServer/merchants/list', null, 'POST_JSON', data)
// 查询主体配置
export const getQuery = (data: any) => request('payAdminServer/merchants/query', data, 'GET')
// 新增主体配置
export const postSave = (data: any) => request('payAdminServer/merchants/save', null, 'POST_JSON', data)
// 修改主体配置
export const postEdit = (data: any) => request('payAdminServer/merchants/edit', null, 'POST_JSON', data)
// 抖音增值税税率查询接口
export const getSysconfig = () => request('payAdminServer/sysconfig/config/DOUYIN_VALUE_ADDED_TAX_RATE', null, 'GET', null)
// 账号列表查询接口
export const queryAccountList = (data:any) => request('/payAdminServer/account/list', null, 'POST_JSON', data)
// 账号详情查询接口
export const queryAccountInfo = (data:any) => request('/payAdminServer/account/query', null, 'POST_JSON', data)
// 账号编辑接口
export const saveAccountInfo = (data:any) => request('/payAdminServer/account/save', null, 'POST_JSON', data)
// 计算分成比例
export const calDivide = (data:any) => request('/payAdminServer/app/calDivide', null, 'POST_JSON', data)

